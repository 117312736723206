import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';
import PostEvent from '../components/PostEvent';

const Veranstaltungen = ({ data }) => (
  <Layout
    title={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.title}
    description={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.description}
  >
    <div className="common-page article-page">
      <Subheader title="VERANSTALTUNGEN" intro={data.datoCmsFixtext.fixtext} />

      {data.datoCmsFixtext.introtext && (
        <div
          className="container"
          dangerouslySetInnerHTML={{ __html: data.datoCmsFixtext.introtext }}
        />
      )}

      <div className="article-feed">
        {data.allDatoCmsVeranstaltungen.edges.map(({ node }) => (
          <PostEvent key={node.id} node={node} />
        ))}
      </div>
    </div>
  </Layout>
);

export default Veranstaltungen;

export const query = graphql`
  query VeranstaltungenQuery {
    allDatoCmsVeranstaltungen(
      sort: { fields: [beginn], order: DESC }
      filter: { art: { ne: "Schulung" } }
    ) {
      edges {
        node {
          ...DatoVeranstaltungenFields
        }
      }
    }

    datoCmsFixtext(name: { eq: "Veranstaltungen" }) {
      fixtext
      introtext
      seo {
        title
        description
      }
    }
  }
`;
